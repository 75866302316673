const initialState = {
    events: [],
    isLoading: false,
    errorMessage: null,
  };
  
  const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_EVENTS_REQUEST':
        return { ...state, isLoading: true, errorMessage: null };
      case 'FETCH_EVENTS_SUCCESS':
        return { ...state, isLoading: false, events: action.payload };
      case 'FETCH_EVENTS_FAILURE':
        return { ...state, isLoading: false, errorMessage: action.error };
      default:
        return state;
    }
  };
  
  export default eventsReducer;
  
// // // src/redux/reducers/programsSlice.js
// // import { createSlice } from '@reduxjs/toolkit';

// // const initialState = {
// //   programs: [],
// //   isLoading: false,
// //   errorMessage: '',
// // };

// // const programsSlice = createSlice({
// //   name: 'programs',
// //   initialState,
// //   reducers: {
// //     fetchProgramsStart(state) {
// //       state.isLoading = true;
// //     },
// //     fetchProgramsSuccess(state, action) {
// //       state.isLoading = false;
// //       state.programs = action.payload;
// //     },
// //     fetchProgramsFailure(state, action) {
// //       state.isLoading = false;
// //       state.errorMessage = action.payload;
// //     },
// //   },
// // });

// // export const { fetchProgramsStart, fetchProgramsSuccess, fetchProgramsFailure } = programsSlice.actions;
// // export default programsSlice.reducer;

// // src/redux/reducers/programsSlice.js
// import { createSlice } from '@reduxjs/toolkit';
// import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';

// dayjs.extend(customParseFormat);

// const initialState = {
//   programs: [],
//   currentProgram: null,
//   isLoading: false,
//   errorMessage: '',
// };

// const updateCurrentProgram = (programs) => {
//   const currentTime = dayjs();
//   let currentProgram = null;

//   for (const program of programs) {
//     const fromTime = dayjs(program.fromTime, 'hh:mm A');
//     const toTime = dayjs(program.toTime, 'hh:mm A');
//     const todayFromTime = currentTime
//       .set('hour', fromTime.hour())
//       .set('minute', fromTime.minute())
//       .set('second', 0);
//     const todayToTime = todayFromTime
//       .add(toTime.hour() - fromTime.hour(), 'hour')
//       .add(toTime.minute() - fromTime.minute(), 'minute');

//     if (currentTime.isAfter(todayFromTime) && currentTime.isBefore(todayToTime)) {
//       currentProgram = program;
//       break;
//     }
//   }

//   return currentProgram || {
//     showPic: 'https://via.placeholder.com/150',
//     title: 'Radio Surabhi Playlist',
//     rjName: '',
//   };
// };

// const programsSlice = createSlice({
//   name: 'programs',
//   initialState,
//   reducers: {
//     fetchProgramsStart(state) {
//       state.isLoading = true;
//     },
//     fetchProgramsSuccess(state, action) {
//       state.isLoading = false;
//       state.programs = action.payload;
//       state.currentProgram = updateCurrentProgram(action.payload);
//     },
//     fetchProgramsFailure(state, action) {
//       state.isLoading = false;
//       state.errorMessage = action.payload;
//     },
//     updateCurrentProgramState(state) {
//       state.currentProgram = updateCurrentProgram(state.programs);
//     },
//   },
// });

// export const {
//   fetchProgramsStart,
//   fetchProgramsSuccess,
//   fetchProgramsFailure,
//   updateCurrentProgramState,
// } = programsSlice.actions;
// export default programsSlice.reducer;


// src/redux/reducers/programsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  programs: [],
  currentRunningProgram: {
    id: null,
    title: 'Radio Surabahi Playlist',
    rjName: null,
    showPic: 'assets/logo.png',
  },
  loading: false,
  error: null,
};

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    fetchProgramsStart(state) {
      state.loading = true;
    },
    fetchProgramsSuccess(state, action) {
      state.loading = false;
      state.programs = action.payload.programs;
      state.currentRunningProgram = action.payload.currentRunningProgram;
    },
    fetchProgramsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateCurrentRunningProgram(state, action) {
      state.currentRunningProgram = action.payload;
    },
  },
});

export const {
  fetchProgramsStart,
  fetchProgramsSuccess,
  fetchProgramsFailure,
  updateCurrentRunningProgram,
} = programsSlice.actions;

export default programsSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchUserData, updateUserProfile } from '../redux/actions/userActions';
import { FaCamera } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Profile = () => {
  const dispatch = useDispatch();
  const { userData, isLoading, errorMessage } = useSelector((state) => state.user);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState(new Date());
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);

  useEffect(() => {
    // dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setPhone(userData.phone);
      setDob(new Date(userData.dob));
      setPhotoPreview(userData.profilePic);
    }
  }, [userData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPhotoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result.split(',')[1];
    //   dispatch(updateUserProfile(name, email, phone, dob.toISOString().split('T')[0], base64Image));
    };
    if (photo) {
      reader.readAsDataURL(photo);
    } else {
    //   dispatch(updateUserProfile(name, email, phone, dob.toISOString().split('T')[0], photoPreview));
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (errorMessage) return <p>Error: {errorMessage}</p>;

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white dark:bg-gray-800 shadow-md rounded-lg">
      <div className="flex justify-center mb-6">
        <div className="relative">
          <img
            src={photoPreview || 'default-profile.png'}
            alt="Profile"
            className="w-32 h-32 rounded-full object-cover"
          />
          <label htmlFor="imageUpload" className="absolute bottom-0 right-0 bg-white p-2 rounded-full shadow cursor-pointer">
            <FaCamera />
            <input
              id="imageUpload"
              type="file"
              onChange={handleImageChange}
              className="hidden"
            />
          </label>
        </div>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700 dark:text-gray-200 font-bold mb-2">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div>
          <label className="block text-gray-700 dark:text-gray-200 font-bold mb-2">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div>
          <label className="block text-gray-700 dark:text-gray-200 font-bold mb-2">Mobile</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div>
          <label className="block text-gray-700 dark:text-gray-200 font-bold mb-2">Date of Birth</label>
          <DatePicker
            selected={dob}
            onChange={(date) => setDob(date)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <button
          onClick={handleSubmit}
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Profile;

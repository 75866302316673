// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   signInWithGoogle,
//   fetchGoogleSignInSession,
//   signUpUser,
//   confirmUserSignUp,
//   autoLoginUser,
// } from '../redux/actions/authActions';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
// import { Link, useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';

// const Signup = () => {
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [otp, setOtp] = useState('');
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [uuid] = useState(uuidv4());

//   const [firstNameError, setFirstNameError] = useState(null);
//   const [lastNameError, setLastNameError] = useState(null);
//   const [emailError, setEmailError] = useState(null);
//   const [passwordError, setPasswordError] = useState(null);

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const isLoading = useSelector((state) => state.user.isLoading);
//   const errorMessage = useSelector((state) => state.user.errorMessage);

//   const validateFields = () => {
//     let isValid = true;
//     setFirstNameError(null);
//     setLastNameError(null);
//     setEmailError(null);
//     setPasswordError(null);

//     if (!firstName) {
//       setFirstNameError('Please enter your First Name');
//       isValid = false;
//     }
//     if (!lastName) {
//       setLastNameError('Please enter your Last Name');
//       isValid = false;
//     }
//     const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
//     if (!email || !emailPattern.test(email)) {
//       setEmailError('Please enter a valid email address');
//       isValid = false;
//     }
//     if (password.length < 8) {
//       setPasswordError('Password should be at least 8 characters');
//       isValid = false;
//     }
//     return isValid;
//   };

//   const handleSignUp = async () => {
//     if (password !== confirmPassword) {
//       alert('Passwords do not match!');
//       return;
//     }

//     if (!validateFields()) {
//       return;
//     }

//     const userData = {
//       email,
//       firstName,
//       lastName,
//       phoneNumber,
//       uuid,
//     };
//     const response = await dispatch(signUpUser({ email, password, userData }));
//     if (response.success) {
//       setIsOtpSent(true);
//     }
//   };

//   const handleConfirmSignUp = async () => {
//     const response = await dispatch(confirmUserSignUp({ email, code: otp }));
//     if (response.success) {
//       await dispatch(autoLoginUser({ email, password }));
//       navigate('/');
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     await dispatch(signInWithGoogle());
//     const result = await dispatch(fetchGoogleSignInSession());
//     if (result) {
//       const email = result.email;
//       const userExists = await checkUserExists(email);

//       if (!userExists) {
//         await dispatch(signUpUser({
//           firstName: result['custom:firstName'] || 'New',
//           lastName: result['custom:lastName'] || 'User',
//           email,
//           password: "RS-user@123",
//           phoneNumber: result['phoneNumber'] || '',
//           country: result['country'] || 'US',
//           uuid: result['custom:uuid'],
//           profilePic: result['profile'] || '',
//         }));
//       }

//       await dispatch(autoLoginUser({ email, password: "RS-user@123" }));
//       navigate('/');
//     }
//   };

//   const checkUserExists = async (email) => {
//     const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists';
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });

//       if (response.ok) {
//         const jsonData = await response.json();
//         return jsonData.userExists;
//       } else {
//         console.log(`Failed to check if user exists: ${response.status}`);
//         return false;
//       }
//     } catch (e) {
//       console.log(`Error checking if user exists: ${e}`);
//       return false;
//     }
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-50">
//       <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
//         <div className="flex justify-between items-center">
//           <h2 className="text-2xl font-extrabold text-gray-900">
//             Register with <span className="text-blue-600">Radio Surabhi</span>
//           </h2>
//           <Link to="/">
//             <FontAwesomeIcon icon={faHome} className="text-gray-500 cursor-pointer" />
//           </Link>
//         </div>
//         <p className="text-center text-sm text-gray-600">
//           It's time to join with Listen and gain full awesome music experience.
//         </p>
//         <button
//           className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2"
//           onClick={handleGoogleSignIn}
//         >
//           <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
//           <span>Register with Google</span>
//         </button>
//         <div className="flex items-center justify-between space-x-3">
//           <span className="w-full border-t border-gray-300"></span>
//           <span className="text-gray-600">OR</span>
//           <span className="w-full border-t border-gray-300"></span>
//         </div>
//         {isOtpSent ? (
//           <div className="space-y-6">
//             <div className="rounded-md shadow-sm space-y-4">
//               <div>
//                 <label htmlFor="otp" className="sr-only">
//                   OTP
//                 </label>
//                 <input
//                   id="otp"
//                   name="otp"
//                   type="text"
//                   required
//                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                   placeholder="OTP"
//                   value={otp}
//                   onChange={(e) => setOtp(e.target.value)}
//                 />
//               </div>
//             </div>
//             <button
//               onClick={handleConfirmSignUp}
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               Confirm
//             </button>
//           </div>
//         ) : (
//           <form className="space-y-6" onSubmit={e => e.preventDefault()}>
//             <div className="rounded-md shadow-sm space-y-4">
//               <div>
//                 <label htmlFor="first-name" className="sr-only">
//                   First Name
//                 </label>
//                 <input
//                   id="first-name"
//                   name="first-name"
//                   type="text"
//                   required
//                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                   placeholder="First Name"
//                   value={firstName}
//                   onChange={(e) => setFirstName(e.target.value)}
//                 />
//                 {firstNameError && <p className="text-red-500 text-xs">{firstNameError}</p>}
//               </div>
//               <div>
//                 <label htmlFor="last-name" className="sr-only">
//                   Last Name
//                 </label>
//                 <input
//                   id="last-name"
//                   name="last-name"
//                   type="text"
//                   required
//                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                   placeholder="Last Name"
//                   value={lastName}
//                   onChange={(e) => setLastName(e.target.value)}
//                 />
//                 {lastNameError && <p className="text-red-500 text-xs">{lastNameError}</p>}
//               </div>
//               <div>
//                 <label htmlFor="email-address" className="sr-only">
//                   Email address
//                 </label>
//                 <input
//                   id="email-address"
//                   name="email"
//                   type="email"
//                   autoComplete="email"
//                   required
//                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                   placeholder="Email address"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//                 {emailError && <p className="text-red-500 text-xs">{emailError}</p>}
//               </div>
//               <div>
//                 <label htmlFor="phone-number" className="sr-only">
//                   Phone Number
//                 </label>
//                 <input
//                   id="phone-number"
//                   name="phone-number"
//                   type="tel"
//                   required
//                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                   placeholder="Phone Number"
//                   value={phoneNumber}
//                   onChange={(e) => setPhoneNumber(e.target.value)}
//                 />
//                 {/* {phoneNumberError && <p className="text-red-500 text-xs">{phoneNumberError}</p>} */}
//               </div>
//               <div>
//                 <label htmlFor="password" className="sr-only">
//                   Password
//                 </label>
//                 <input
//                   id="password"
//                   name="password"
//                   type="password"
//                   autoComplete="current-password"
//                   required
//                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                 />
//                 {passwordError && <p className="text-red-500 text-xs">{passwordError}</p>}
//               </div>
//               <div>
//                 <label htmlFor="confirm-password" className="sr-only">
//                   Confirm Password
//                 </label>
//                 <input
//                   id="confirm-password"
//                   name="confirm-password"
//                   type="password"
//                   autoComplete="current-password"
//                   required
//                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
//                   placeholder="Confirm Password"
//                   value={confirmPassword}
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                 />
//               </div>
//             </div>
//             <button
//               onClick={handleSignUp}
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               Register
//             </button>
//           </form>
//         )}
//         <div className="text-center text-sm text-gray-600">
//           By registering, you agree to our <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">Terms & Conditions</a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signup;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  signInWithGoogle,
  fetchGoogleSignInSession,
  signUpUser,
  confirmUserSignUp,
  autoLoginUser,
  updateUserInDatabase,
} from '../redux/actions/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import backgroundImage from '../assets/web-login-bg.jpg';


const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [uuid] = useState(uuidv4());

  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.user.isLoading);
  const errorMessage = useSelector((state) => state.user.errorMessage);

  const validateFields = () => {
    let isValid = true;
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPasswordError(null);

    if (!firstName) {
      setFirstNameError('Please enter your First Name');
      isValid = false;
    }
    if (!lastName) {
      setLastNameError('Please enter your Last Name');
      isValid = false;
    }
    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!email || !emailPattern.test(email)) {
      setEmailError('Please enter a valid email address');
      isValid = false;
    }
    if (password.length < 8) {
      setPasswordError('Password should be at least 8 characters');
      isValid = false;
    }
    return isValid;
  };

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    if (!validateFields()) {
      return;
    }

    const userData = {
      email,
      firstName,
      lastName,
      phoneNumber,
      uuid,
    };
    const response = await dispatch(signUpUser({ email, password, userData }));
    if (response.success) {
      setIsOtpSent(true);
    }
  };

  const handleConfirmSignUp = async () => {
    const response = await dispatch(confirmUserSignUp({ email, code: otp }));
    if (response.success) {
      await dispatch(autoLoginUser({ email, password }));

      // Update the database with user information
      const userData = {
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "password": password,
      "phoneNumber": phoneNumber ?? "",
      "dob": "",
      "country": "",
      "uuid": uuid,
      "profilePic": "",
      };

      try {
        await updateUserInDatabase(userData);
        navigate('/');
      } catch (error) {
        console.error('Error updating user in database:', error);
      }
    }
  };

  // const handleGoogleSignIn = async () => {
  //   await dispatch(signInWithGoogle());
  //   const result = await dispatch(fetchGoogleSignInSession());
  //   if (result) {
  //     const email = result.email;
  //     const userExists = await checkUserExists(email);

  //     if (!userExists) {
  //       await dispatch(signUpUser({
  //         firstName: result['custom:firstName'] || 'New',
  //         lastName: result['custom:lastName'] || 'User',
  //         email,
  //         password: "RS-user@123",
  //         phoneNumber: result['phoneNumber'] || '',
  //         country: result['country'] || 'US',
  //         uuid: result['custom:uuid'],
  //         profilePic: result['profile'] || '',
  //       }));
  //     }

  //     await dispatch(autoLoginUser({ email, password: "RS-user@123" }));
  //     navigate('/');
  //   }
  // };

  const checkUserExists = async (email) => {
    const url = 'https://dcx7xjdzm8.execute-api.us-east-1.amazonaws.com/Stage/user/user-exists';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const jsonData = await response.json();
        return jsonData.userExists;
      } else {
        console.log(`Failed to check if user exists: ${response.status}`);
        return false;
      }
    } catch (e) {
      console.log(`Error checking if user exists: ${e}`);
      return false;
    }
  };

  return (
    
    <div
    className="min-h-screen flex items-center justify-center bg-gray-50"
    style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
  >
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-extrabold text-gray-900">
            Register with <span className="text-blue-600">Radio Surabhi</span>
          </h2>
          <Link to="/">
            <FontAwesomeIcon icon={faHome} className="text-gray-500 cursor-pointer" />
          </Link>
        </div>
        <p className="text-center text-sm text-gray-600">
          It's time to join with Listen and gain full awesome music experience.
        </p>
        {/* <button
          className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-600 bg-white hover:bg-gray-50 flex items-center justify-center space-x-2"
          onClick={handleGoogleSignIn}
        >
          <FontAwesomeIcon icon={faGoogle} className="text-gray-600" />
          <span>Register with Google</span>
        </button>
        <div className="flex items-center justify-between space-x-3">
          <span className="w-full border-t border-gray-300"></span>
          <span className="text-gray-600">OR</span>
          <span className="w-full border-t border-gray-300"></span>
        </div> */}
        {isOtpSent ? (
          <div className="space-y-6">
            <div className="rounded-md shadow-sm space-y-4">
              <div>
                <label htmlFor="otp" className="sr-only">
                  OTP
                </label>
                <input
                  id="otp"
                  name="otp"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={handleConfirmSignUp}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Confirm
            </button>
          </div>
        ) : (
          <form className="space-y-6" onSubmit={e => e.preventDefault()}>
            <div className="rounded-md shadow-sm space-y-4">
              <div>
                <label htmlFor="first-name" className="sr-only">
                  First Name
                </label>
                <input
                  id="first-name"
                  name="first-name"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {firstNameError && <p className="text-red-500 text-xs">{firstNameError}</p>}
              </div>
              <div>
                <label htmlFor="last-name" className="sr-only">
                  Last Name
                </label>
                <input
                  id="last-name"
                  name="last-name"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {lastNameError && <p className="text-red-500 text-xs">{lastNameError}</p>}
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && <p className="text-red-500 text-xs">{emailError}</p>}
              </div>
              {/* <div>
                <label htmlFor="phone-number" className="sr-only">
                  Phone Number
                </label>
                <input
                  id="phone-number"
                  name="phone-number"
                  type="tel"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div> */}
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordError && <p className="text-red-500 text-xs">{passwordError}</p>}
              </div>
              <div>
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={handleSignUp}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Register
            </button>
          </form>
        )}
        <div className="text-center text-sm text-gray-600">
          By registering, you agree to our <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">Terms & Conditions</a>
        </div>
      </div>
    </div>
  );
};

export default Signup;

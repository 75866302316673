// src/redux/reducers/streamingBarSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPlaying: false,
  currentRunningProgram: {
    id: '',
    showPic: '',
    title: '',
    rjName: '',
  },
  liveStreamUrl: 'https://radiosurabhi.streamguys1.com/live1-web',
  archiveUrl: '', // Placeholder for future archive URL functionality
};

const streamingBarSlice = createSlice({
  name: 'streamingBar',
  initialState,
  reducers: {
    togglePlayPause: (state) => {
      state.isPlaying = !state.isPlaying;
    },
    setProgram: (state, action) => {
      state.currentRunningProgram = action.payload;
    },
    setArchiveUrl: (state, action) => {
      state.archiveUrl = action.payload;
    },
  },
});

export const { togglePlayPause, setProgram, setArchiveUrl } = streamingBarSlice.actions;

export default streamingBarSlice.reducer;

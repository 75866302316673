// src/components/NewsPopup.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';

const NewsPopup = ({ news, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl h-3/4 bg-white dark:bg-gray-800 rounded-lg overflow-y-scroll">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-700 dark:text-gray-300">
          <FaTimes size={24} />
        </button>
        <img src={news.thumbnail} alt={news.title} className="w-full h-1/3 object-cover rounded-t-lg" />
        <div className="p-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">{news.title}</h2>
          <p className="text-sm text-gray-600 dark:text-gray-400">Available till: {new Date(news.updatedAt).toLocaleDateString()}</p>
          <div className="mt-4 text-gray-900 dark:text-gray-100">{news.newsContent}</div>
        </div>
      </div>
    </div>
  );
};

export default NewsPopup;

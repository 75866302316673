import { useEffect, useState } from 'react';
import Ably from 'ably';

export const useAbly = (channelName) => {
  const [comments, setComments] = useState([]);
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    const ably = new Ably.Realtime('BabKng.-ohfww:tKYIugBZoEvj-kXZNwCyvRSvxzzD4CLNso7G5Qs-hQg');
    const ablyChannel = ably.channels.get(channelName);

    ablyChannel.subscribe((message) => {
      setComments((prevComments) => [...prevComments, message]);
    });

    setChannel(ablyChannel);

    return () => {
      ablyChannel.unsubscribe();
      ably.close();
    };
  }, [channelName]);

  const newComment = (message) => {
    if (channel) {
      channel.publish('comment', message);
    }
  };

  return { comments, newComment };
};

// src/redux/reducers/adsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ads: [],
  loading: false,
  error: null,
};

const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    fetchAdsStart(state) {
      state.loading = true;
    },
    fetchAdsSuccess(state, action) {
      state.loading = false;
      state.ads = action.payload;
    },
    fetchAdsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAdsStart, fetchAdsSuccess, fetchAdsFailure } = adsSlice.actions;
export default adsSlice.reducer;

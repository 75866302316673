// // src/components/StreamingBar.js
// import React, { useEffect, useRef, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { FaPlay, FaPause, FaVolumeUp, FaBroadcastTower } from 'react-icons/fa';
// import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
// import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';

// const StreamingBar = ({ isCollapsed }) => {
//   const dispatch = useDispatch();
//   const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
//   const audioRef = useRef(new Audio());
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isHovered, setIsHovered] = useState(false);

//   useEffect(() => {
//     const audio = audioRef.current;

//     const updateCurrentTime = () => setCurrentTime(audio.currentTime);
//     const updateDuration = () => setDuration(audio.duration);

//     audio.addEventListener('timeupdate', updateCurrentTime);
//     audio.addEventListener('loadedmetadata', updateDuration);

//     if (archiveUrl) {
//       audio.src = archiveUrl;
//     } else {
//       audio.src = liveStreamUrl;
//     }

//     if (isPlaying) {
//       audio.play();
//     } else {
//       audio.pause();
//     }

//     return () => {
//       audio.removeEventListener('timeupdate', updateCurrentTime);
//       audio.removeEventListener('loadedmetadata', updateDuration);
//       audio.pause();
//     };
//   }, [isPlaying, liveStreamUrl, archiveUrl]);

//   const handleTogglePlayPause = () => {
//     dispatch(togglePlayPause());
//   };

//   const handleGoLive = () => {
//     dispatch(setArchiveUrl(''));
//     dispatch(updateCurrentRunningProgram({
//       title: 'Live Program Title', // Replace with the actual live program title
//       rjName: 'RJ Name', // Replace with the actual RJ name
//       showPic: 'https://path.to.live.show.pic', // Replace with the actual live show picture
//     }));
//     dispatch(togglePlayPause()); // Ensure the audio starts playing
//   };

//   const formatTime = (seconds) => {
//     const h = Math.floor(seconds / 3600);
//     const m = Math.floor((seconds % 3600) / 60);
//     const s = Math.floor(seconds % 60);
//     return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
//   };

//   const leftPosition = isCollapsed ? 'left-[10rem]' : 'left-[20rem]';

//   return (
//     <div
//       className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center p-4 h-14 fixed bottom-4 ${leftPosition} right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {archiveUrl && isHovered && (
//         <div className="absolute top-0 left-0 w-full flex items-center">
//           <span className="text-xs text-gray-600 dark:text-gray-400 ml-4">{formatTime(currentTime)}</span>
//           <input
//             type="range"
//             className="flex-1 mx-2"
//             value={currentTime}
//             max={duration}
//             onChange={(e) => {
//               audioRef.current.currentTime = e.target.value;
//               setCurrentTime(e.target.value);
//             }}
//           />
//           <span className="text-xs text-gray-600 dark:text-gray-400 mr-4">{formatTime(duration)}</span>
//         </div>
//       )}
//       <div className="flex items-center flex-shrink-0">
//         <img src={currentRunningProgram.showPic} alt="Show" className="w-10 h-10 object-cover rounded-lg mr-4" />
//         <div className='p-4'>
//           <h3 className="text-sm font-semibold">{currentRunningProgram.title}</h3>
//           <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
//         </div>
//       </div>
//       <div className="flex flex-1 justify-center items-center space-x-4">
//         <button onClick={handleTogglePlayPause} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 text-2xl">
//           {isPlaying ? <FaPause /> : <FaPlay />}
//         </button>
//       </div>
//       <div className="flex items-center flex-shrink-0 ml-4 space-x-2">
//         {archiveUrl && (
//           <button onClick={handleGoLive} className="text-blue-500 hover:text-blue-700 flex items-center">
//             <FaBroadcastTower className="mr-2" /> Go Live
//           </button>
//         )}
//         <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
//           <FaVolumeUp />
//         </button>
//       </div>
//     </div>
//   );
// };
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaPlay, FaPause, FaVolumeUp, FaBroadcastTower, FaComments } from 'react-icons/fa';
import { togglePlayPause, setArchiveUrl } from '../redux/reducers/streamingBarSlice';
import { updateCurrentRunningProgram } from '../redux/reducers/programsSlice';
import { useNavigate } from 'react-router-dom';
import onAirImage from '../assets/on air.png'; // Import the "On Air" image

const StreamingBar = ({ isCollapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
  const { programs } = useSelector((state) => state.programs);
  const audioRef = useRef(new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [volume, setVolume] = useState(1); // State to manage volume
  const [isVolumeOpen, setIsVolumeOpen] = useState(false); // State to toggle volume slider visibility

  const convertTimeToMinutes = (timeString) => {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const getUpcomingProgram = () => {
    const now = new Date();
    const nowTime = now.getHours() * 60 + now.getMinutes();
    for (const program of programs) {
      const startTime = convertTimeToMinutes(program.fromTime);
      if (startTime > nowTime) {
        return program;
      }
    }
    return null;
  };
  const upcomingProgram = getUpcomingProgram();

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => setDuration(audio.duration);

    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('loadedmetadata', updateDuration);

    if (archiveUrl) {
      audio.src = archiveUrl;
    } else {
      audio.src = liveStreamUrl;
    }

    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('loadedmetadata', updateDuration);
      audio.pause();
    };
  }, [isPlaying, liveStreamUrl, archiveUrl]);

  const handleTogglePlayPause = () => {
    dispatch(togglePlayPause());
  };

  const handleGoLive = () => {
    dispatch(setArchiveUrl(''));
    dispatch(updateCurrentRunningProgram({
      id: null,
      title: 'Live Program Title',
      rjName: 'RJ Name',
      showPic: 'https://path.to.live.show.pic',
    }));
    dispatch(togglePlayPause());
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(a => a).join(':');
  };

  const leftPosition = isCollapsed ? 'left-[10rem]' : 'left-[20rem]';

  const handleCommentsClick = () => {
    navigate(`/streaming/${currentRunningProgram.id}`);
  };

  const handleVolumeClick = () => {
    setIsVolumeOpen(!isVolumeOpen);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  return (
    <div
      className={`bg-white dark:bg-gray-800 text-black dark:text-white flex items-center justify-between p-4 h-14 fixed bottom-4 ${leftPosition} right-14 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center flex-shrink-0">
        <img src={currentRunningProgram.showPic} alt="Show" className="w-10 h-10 object-cover rounded-lg mr-4" />
        <div>
          <h3 className="text-sm font-semibold flex items-center">
            {currentRunningProgram.title}
            <img src={onAirImage} alt="On Air" className="ml-2 w-10 h-10" />
          </h3>
          <p className="text-xs text-gray-600 dark:text-gray-400">{currentRunningProgram.rjName || ' '}</p>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <div className="relative">
          <button className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400" onClick={handleVolumeClick}>
            <FaVolumeUp />
          </button>
          {isVolumeOpen && (
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              className="absolute bottom-20 right-px transform -translate-x-1/2"
              style={{ writingMode: 'bt-lr', transform: 'rotate(-90deg)' }}
            />
          )}
        </div>
        <button onClick={handleTogglePlayPause} className="text-blue-600 dark:text-blue-600 hover:text-blue-500 dark:hover:text-blue-400 text-3xl">
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        {liveStreamUrl && currentRunningProgram.title !== 'Radio Surabahi Playlist' && (
          <button onClick={handleCommentsClick} className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
            <FaComments />
          </button>
        )}
      </div>
      {archiveUrl && isHovered && (
        <div className="absolute top-0 left-0 w-full flex items-center">
          <span className="text-xs text-gray-600 dark:text-gray-400 ml-4">{formatTime(currentTime)}</span>
          <input
            type="range"
            className="flex-1 mx-2"
            value={currentTime}
            max={duration}
            onChange={(e) => {
              audioRef.current.currentTime = e.target.value;
              setCurrentTime(e.target.value);
            }}
          />
          <span className="text-xs text-gray-600 dark:text-gray-400 mr-4">{formatTime(duration)}</span>
        </div>
      )}
      <div className="flex items-center flex-shrink-0 space-x-2">
        {archiveUrl ? (
          <button onClick={handleGoLive} className="text-blue-500 hover:text-blue-700 flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
            <FaBroadcastTower className="mr-2" /> Go Live
          </button>
        ) : (
          upcomingProgram && (
            <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg hidden md:flex">
              <span className="text-xs text-gray-600 dark:text-gray-400 ml-4">Upcoming: {upcomingProgram.title}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default StreamingBar;

import axios from 'axios';

export const fetchEventsList = () => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_EVENTS_REQUEST' });
    const response = await axios.get('/api/events');
    dispatch({ type: 'FETCH_EVENTS_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_EVENTS_FAILURE', error: error.message });
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchAds } from '../redux/actions/adsActions';
import PopUpAd from './PopUpAd';

const AdManager = () => {
  const dispatch = useDispatch();
  const { ads } = useSelector((state) => state.ads);
  const [showPopUpAd, setShowPopUpAd] = useState(false);
  const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchAds(true)); // Fetch ads for guest
  }, [dispatch]);

  useEffect(() => {
    const popUpAds = ads.filter(ad => ad.type === '1');
    if (popUpAds.length > 0) {
      setCurrentPopUpAd(popUpAds[0]);
      setShowPopUpAd(true);

      const interval = setInterval(() => {
        const nextPopUpAd = popUpAds.find(ad => ad !== currentPopUpAd);
        if (nextPopUpAd) {
          setCurrentPopUpAd(nextPopUpAd);
          setShowPopUpAd(true);
        }
      }, 300000); // Show next pop-up ad every 5 minutes

      return () => clearInterval(interval);
    }
  }, [ads, currentPopUpAd]);

  const closePopUpAd = () => {
    setShowPopUpAd(false);
  };

  if (location.pathname === '/login' || location.pathname === '/signup') {
    return null; // Don't render the PopUpAd on login and signup pages
  }

  return (
    <>
      {showPopUpAd && currentPopUpAd && (
        <PopUpAd ad={currentPopUpAd} onClose={closePopUpAd} />
      )}
    </>
  );
};

export default AdManager;

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchNewsList } from '../redux/actions/newsActions';
// import { fetchCategoryList } from '../redux/actions/categoriesActions';
// import NavigationBar from '../components/NavigationBar';
// import Header from '../components/Header';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css"; // Add a new CSS file for carousel styles
// import { FaHeart, FaRegHeart } from 'react-icons/fa';
// import Slider from "react-slick";

// // Import RJ posters manually
// import RJ1 from '../assets/RJ-posters/1.png';
// import RJ2 from '../assets/RJ-posters/2.png';
// import RJ3 from '../assets/RJ-posters/3.png';
// import RJ4 from '../assets/RJ-posters/4.png';
// import RJ5 from '../assets/RJ-posters/5.png';
// import RJ6 from '../assets/RJ-posters/6.png';
// import RJ7 from '../assets/RJ-posters/7.png';
// import RJ8 from '../assets/RJ-posters/8.png';
// import RJ9 from '../assets/RJ-posters/9.png';
// import RJ10 from '../assets/RJ-posters/10.png';

// const Home = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
//   const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [interestedCategories, setInterestedCategories] = useState([]);

//   const rjPosters = [RJ1, RJ2, RJ3, RJ4, RJ5, RJ6, RJ7, RJ8, RJ9, RJ10];

//   useEffect(() => {
//     dispatch(fetchProgramsList(true)); // Fetch programs for guest
//     dispatch(fetchNewsList(true)); // Fetch news for guest
//     dispatch(fetchCategoryList(true)); // Fetch categories for guest
//   }, [dispatch]);

//   const toggleInterest = (categoryId) => {
//     setInterestedCategories(prevState =>
//       prevState.includes(categoryId)
//         ? prevState.filter(id => id !== categoryId)
//         : [...prevState, categoryId]
//     );
//   };

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//   };

//   return (
//     <div className="relative font-sans">
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-20' : 'ml-64'}`}>
//         <div className="relative h-96">
//           <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
//           <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
//         </div>
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Top <span className="text-blue-600">Shows</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative px-16">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-lg" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* News and Categories Section */}
//         <div className="p-8 pt-0 bg-gray-50 dark:bg-gray-900 flex space-x-16">
//           {/* Latest News Section */}
//           <div className="w-1/2 pr-2">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//               Latest <span className="text-blue-600">News</span>
//             </h2>
//             <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
//             {isNewsLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
//             ) : newsError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
//             ) : (
//               <div className="relative px-16">
//                 <div className="no-scrollbar overflow-x-scroll">
//                   <div className="flex space-x-6">
//                     {news.map((item) => (
//                       <div key={item.SK} className="min-w-[220px] max-w-[200px]">
//                         <img src={item.thumbnail} alt={item.title} className="w-full h-40 object-cover rounded-lg" />
//                         <div className="pt-2">
//                           <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
//                           <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
          
//           {/* Categories Section */}
//           <div className="w-1/2 pl-2">
//             <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//               Categories
//             </p>
//             {isCategoriesLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//             ) : categoriesError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//             ) : (
//               <div className="relative h-72 overflow-y-scroll no-scrollbar px-6">
//                 <div className="flex flex-col space-y-6">
//                   {categories.map((category) => (
//                     <div key={category.PK} className="flex items-center space-x-4">
//                       <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
//                       <div className="ml-4 flex-1">
//                         <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
//                       </div>
//                       <button onClick={() => toggleInterest(category.PK)} className="ml-4 px-20">
//                         {interestedCategories.includes(category.PK) ? (
//                           <FaHeart className="text-red-500" />
//                         ) : (
//                           <FaRegHeart className="text-gray-400" />
//                         )}
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Our RJs Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">RJs</span>
//           </h2>
//           <div className="relative px-16 carousel-wrapper">
//             <Slider {...carouselSettings}>
//               {rjPosters.map((poster, index) => (
//                 <div key={index} className="p-14">
//                   <div className="carousel-item">
//                     <img src={poster} alt={`RJ ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;

// // src/pages/Home.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchNewsList } from '../redux/actions/newsActions';
// import { fetchCategoryList } from '../redux/actions/categoriesActions';
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css"; // Add a new CSS file for carousel styles
// import { FaHeart, FaRegHeart } from 'react-icons/fa';
// import Slider from "react-slick";
// import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component

// // Import RJ posters manually
// import RJ1 from '../assets/RJ-posters/1.png';
// import RJ2 from '../assets/RJ-posters/2.png';
// import RJ3 from '../assets/RJ-posters/3.png';
// import RJ4 from '../assets/RJ-posters/4.png';
// import RJ5 from '../assets/RJ-posters/5.png';
// import RJ6 from '../assets/RJ-posters/6.png';
// import RJ7 from '../assets/RJ-posters/7.png';
// import RJ8 from '../assets/RJ-posters/8.png';
// import RJ9 from '../assets/RJ-posters/9.png';
// import RJ10 from '../assets/RJ-posters/10.png';

// const Home = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
//   const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [interestedCategories, setInterestedCategories] = useState([]);

//   const rjPosters = [RJ1, RJ2, RJ3, RJ4, RJ5, RJ6, RJ7, RJ8, RJ9, RJ10];

//   useEffect(() => {
//     dispatch(fetchProgramsList(true)); // Fetch programs for guest
//     dispatch(fetchNewsList(true)); // Fetch news for guest
//     dispatch(fetchCategoryList(true)); // Fetch categories for guest
//   }, [dispatch]);

//   const toggleInterest = (categoryId) => {
//     setInterestedCategories(prevState =>
//       prevState.includes(categoryId)
//         ? prevState.filter(id => id !== categoryId)
//         : [...prevState, categoryId]
//     );
//   };

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//   };

//   return (
//     <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-20' : 'ml-64'}`}>
//         <div className="relative h-96">
//           <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
//           <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
//         </div>
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Top <span className="text-blue-600">Shows</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative px-16">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-lg" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* News and Categories Section */}
//         <div className="p-8 pt-0 bg-gray-50 dark:bg-gray-900 flex space-x-16">
//           {/* Latest News Section */}
//           <div className="w-1/2 pr-2">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//               Latest <span className="text-blue-600">News</span>
//             </h2>
//             <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
//             {isNewsLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
//             ) : newsError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
//             ) : (
//               <div className="relative px-16">
//                 <div className="no-scrollbar overflow-x-scroll">
//                   <div className="flex space-x-6">
//                     {news.map((item) => (
//                       <div key={item.SK} className="min-w-[220px] max-w-[200px]">
//                         <img src={item.thumbnail} alt={item.title} className="w-full h-40 object-cover rounded-lg" />
//                         <div className="pt-2">
//                           <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
//                           <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
          
//           {/* Categories Section */}
//           <div className="w-1/2 pl-2">
//             <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//               Categories
//             </p>
//             {isCategoriesLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//             ) : categoriesError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//             ) : (
//               <div className="relative h-72 overflow-y-scroll no-scrollbar px-6">
//                 <div className="flex flex-col space-y-6">
//                   {categories.map((category) => (
//                     <div key={category.PK} className="flex items-center space-x-4">
//                       <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
//                       <div className="ml-4 flex-1">
//                         <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
//                       </div>
//                       <button onClick={() => toggleInterest(category.PK)} className="ml-4 px-20">
//                         {interestedCategories.includes(category.PK) ? (
//                           <FaHeart className="text-red-500" />
//                         ) : (
//                           <FaRegHeart className="text-gray-400" />
//                         )}
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Our RJs Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">RJs</span>
//           </h2>
//           <div className="relative px-16 carousel-wrapper">
//             <Slider {...carouselSettings}>
//               {rjPosters.map((poster, index) => (
//                 <div key={index} className="p-14">
//                   <div className="carousel-item">
//                     <img src={poster} alt={`RJ ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
        
//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// export default Home;

// // src/pages/Home.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchNewsList } from '../redux/actions/newsActions';
// import { fetchCategoryList } from '../redux/actions/categoriesActions';
// import { fetchAds } from '../redux/actions/adsActions'; // Import the fetchAds action
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css"; // Add a new CSS file for carousel styles
// import { FaHeart, FaRegHeart } from 'react-icons/fa';
// import Slider from "react-slick";
// import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component
// import PopUpAd from '../components/PopUpAd'; // Import the PopUpAd component

// // Import RJ posters manually
// import RJ1 from '../assets/RJ-posters/1.png';
// import RJ2 from '../assets/RJ-posters/2.png';
// import RJ3 from '../assets/RJ-posters/3.png';
// import RJ4 from '../assets/RJ-posters/4.png';
// import RJ5 from '../assets/RJ-posters/5.png';
// import RJ6 from '../assets/RJ-posters/6.png';
// import RJ7 from '../assets/RJ-posters/7.png';
// import RJ8 from '../assets/RJ-posters/8.png';
// import RJ9 from '../assets/RJ-posters/9.png';
// import RJ10 from '../assets/RJ-posters/10.png';

// const Home = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
//   const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);
//   const { ads, isLoading: isAdsLoading, errorMessage: adsError } = useSelector((state) => state.ads); // Get ads from the Redux state

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [interestedCategories, setInterestedCategories] = useState([]);
//   const [showPopUpAd, setShowPopUpAd] = useState(false);
//   const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);

//   const rjPosters = [RJ1, RJ2, RJ3, RJ4, RJ5, RJ6, RJ7, RJ8, RJ9, RJ10];

//   useEffect(() => {
//     dispatch(fetchProgramsList(true)); // Fetch programs for guest
//     dispatch(fetchNewsList(true)); // Fetch news for guest
//     dispatch(fetchCategoryList(true)); // Fetch categories for guest
//     dispatch(fetchAds(true)); // Fetch ads for guest
//   }, [dispatch]);

//   // useEffect(() => {
//   //   const popUpAds = ads.filter(ad => ad.type === '1');
//   //   if (popUpAds.length > 0) {
//   //     setCurrentPopUpAd(popUpAds[0]);
//   //     setShowPopUpAd(true);
//   //   }
//   // }, [ads]);

//   const bannerAds = ads.filter(ad => ad.type === '2');
//   const currentBannerAd = bannerAds[bannerAdIndex % bannerAds.length];


//   const toggleInterest = (categoryId) => {
//     setInterestedCategories(prevState =>
//       prevState.includes(categoryId)
//         ? prevState.filter(id => id !== categoryId)
//         : [...prevState, categoryId]
//     );
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setBannerAdIndex(prevIndex => prevIndex + 1);
//     }, 300000); // Rotate banner ad every 5 minutes

//     return () => clearInterval(interval);
//   }, []);

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//   };

//   return (
//     <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
//       {/* {showPopUpAd && currentPopUpAd && (
//         <PopUpAd ad={currentPopUpAd} onClose={closePopUpAd} />
//       )} */}
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-20' : 'ml-64'}`}>
//         <div className="relative h-96">
//           <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
//           <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
//         </div>
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Top <span className="text-blue-600">Shows</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative px-16">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-lg" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* News and Categories Section */}
//         <div className="p-8 pt-0 bg-gray-50 dark:bg-gray-900 flex space-x-16">
//           {/* Latest News Section */}
//           <div className="w-1/2 pr-2">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//               Latest <span className="text-blue-600">News</span>
//             </h2>
//             <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
//             {isNewsLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
//             ) : newsError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
//             ) : (
//               <div className="relative px-16">
//                 <div className="no-scrollbar overflow-x-scroll">
//                   <div className="flex space-x-6">
//                     {news.map((item) => (
//                       <div key={item.SK} className="min-w-[220px] max-w-[200px]">
//                         <img src={item.thumbnail} alt={item.title} className="w-full h-40 object-cover rounded-lg" />
//                         <div className="pt-2">
//                           <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
//                           <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
          
//           {/* Categories Section */}
//           <div className="w-1/2 pl-2">
//             <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//               Categories
//             </p>
//             {isCategoriesLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//             ) : categoriesError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//             ) : (
//               <div className="relative h-72 overflow-y-scroll no-scrollbar px-6">
//                 <div className="flex flex-col space-y-6">
//                   {categories.map((category) => (
//                     <div key={category.PK} className="flex items-center space-x-4">
//                       <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
//                       <div className="ml-4 flex-1">
//                         <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
//                       </div>
//                       <button onClick={() => toggleInterest(category.PK)} className="ml-4 px-20">
//                         {interestedCategories.includes(category.PK) ? (
//                           <FaHeart className="text-red-500" />
//                         ) : (
//                           <FaRegHeart className="text-gray-400" />
//                         )}
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Banner Ads Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           {isAdsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative px-16">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-full h-60 object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Our RJs Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">RJs</span>
//           </h2>
//           <div className="relative px-16 carousel-wrapper">
//             <Slider {...carouselSettings}>
//               {rjPosters.map((poster, index) => (
//                 <div key={index} className="p-14">
//                   <div className="carousel-item">
//                     <img src={poster} alt={`RJ ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
        
//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// export default Home;

// // src/pages/Home4.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchNewsList } from '../redux/actions/newsActions';
// import { fetchCategoryList } from '../redux/actions/categoriesActions';
// import { fetchAds } from '../redux/actions/adsActions'; // Import the fetchAds action
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css"; // Add a new CSS file for carousel styles
// import { FaHeart, FaRegHeart } from 'react-icons/fa';
// import Slider from "react-slick";
// import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component
// import PopUpAd from '../components/PopUpAd'; // Import the PopUpAd component
// import NewsPopup from '../components/NewsPopup'; // Import the NewsPopup component

// // Import RJ posters manually
// import RJ1 from '../assets/RJ-posters/1.png';
// import RJ2 from '../assets/RJ-posters/2.png';
// import RJ3 from '../assets/RJ-posters/3.png';
// import RJ4 from '../assets/RJ-posters/4.png';
// import RJ5 from '../assets/RJ-posters/5.png';
// import RJ6 from '../assets/RJ-posters/6.png';
// import RJ7 from '../assets/RJ-posters/7.png';
// import RJ8 from '../assets/RJ-posters/8.png';
// import RJ9 from '../assets/RJ-posters/9.png';
// import RJ10 from '../assets/RJ-posters/10.png';

// const Home = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
//   const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);
//   const { ads, isLoading: isAdsLoading, errorMessage: adsError } = useSelector((state) => state.ads); // Get ads from the Redux state

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [interestedCategories, setInterestedCategories] = useState([]);
//   const [showPopUpAd, setShowPopUpAd] = useState(false);
//   const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);
//   const [selectedNews, setSelectedNews] = useState(null); // Add state for selected news

//   const rjPosters = [RJ1, RJ2, RJ3, RJ4, RJ5, RJ6, RJ7, RJ8, RJ9, RJ10];

//   useEffect(() => {
//     dispatch(fetchProgramsList(true)); // Fetch programs for guest
//     dispatch(fetchNewsList(true)); // Fetch news for guest
//     dispatch(fetchCategoryList(true)); // Fetch categories for guest
//     dispatch(fetchAds(true)); // Fetch ads for guest
//   }, [dispatch]);

//   const bannerAds = ads.filter(ad => ad.type === '2');
//   const currentBannerAd = bannerAds[bannerAdIndex % bannerAds.length];

//   const toggleInterest = (categoryId) => {
//     setInterestedCategories(prevState =>
//       prevState.includes(categoryId)
//         ? prevState.filter(id => id !== categoryId)
//         : [...prevState, categoryId]
//     );
//   };

//   const handleNewsClick = (newsItem) => {
//     setSelectedNews(newsItem);
//   };

//   const closeNewsPopup = () => {
//     setSelectedNews(null);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setBannerAdIndex(prevIndex => prevIndex + 1);
//     }, 300000); // Rotate banner ad every 5 minutes

//     return () => clearInterval(interval);
//   }, []);

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//   };

//   return (
//     <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
//       {/* {showPopUpAd && currentPopUpAd && (
//         <PopUpAd ad={currentPopUpAd} onClose={closePopUpAd} />
//       )} */}
//       {selectedNews && (
//         <NewsPopup news={selectedNews} onClose={closeNewsPopup} />
//       )}
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-20' : 'ml-64'}`}>
//         <div className="relative h-96">
//           <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
//           <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
//         </div>
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Top <span className="text-blue-600">Shows</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative px-16">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* News and Categories Section */}
//         <div className="p-8 pt-0 bg-gray-50 dark:bg-gray-900 flex space-x-16">
//           {/* Latest News Section */}
//           <div className="w-1/2 pr-2">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//               Latest <span className="text-blue-600">News</span>
//             </h2>
//             <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
//             {isNewsLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
//             ) : newsError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
//             ) : (
//               <div className="relative px-16">
//                 <div className="no-scrollbar overflow-x-scroll">
//                   <div className="flex space-x-6">
//                     {news.map((item) => (
//                       <div key={item.SK} className="min-w-[220px] max-w-[200px]" onClick={() => handleNewsClick(item)}>
//                         <img src={item.thumbnail} alt={item.title} className="w-full h-40 object-cover rounded-lg" />
//                         <div className="pt-2">
//                           <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
//                           <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
          
//           {/* Categories Section */}
//           <div className="w-1/2 pl-2">
//             <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//               Categories
//             </p>
//             {isCategoriesLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//             ) : categoriesError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//             ) : (
//               <div className="relative h-72 overflow-y-scroll no-scrollbar px-6">
//                 <div className="flex flex-col space-y-6">
//                   {categories.map((category) => (
//                     <div key={category.PK} className="flex items-center space-x-4">
//                       <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
//                       <div className="ml-4 flex-1">
//                         <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
//                       </div>
//                       <button onClick={() => toggleInterest(category.PK)} className="ml-4 px-20">
//                         {interestedCategories.includes(category.PK) ? (
//                           <FaHeart className="text-red-500" />
//                         ) : (
//                           <FaRegHeart className="text-gray-400" />
//                         )}
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Banner Ads Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           {isAdsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative px-16">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-full h-60 object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Our RJs Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">RJs</span>
//           </h2>
//           <div className="relative px-16 carousel-wrapper">
//             <Slider {...carouselSettings}>
//               {rjPosters.map((poster, index) => (
//                 <div key={index} className="p-14">
//                   <div className="carousel-item">
//                     <img src={poster} alt={`RJ ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
        
//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// export default Home;

// // src/pages/Home4.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchNewsList } from '../redux/actions/newsActions';
// import { fetchCategoryList } from '../redux/actions/categoriesActions';
// import { fetchAds } from '../redux/actions/adsActions'; // Import the fetchAds action
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css"; // Add a new CSS file for carousel styles
// import { FaHeart, FaRegHeart } from 'react-icons/fa';
// import Slider from "react-slick";
// import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component
// import PopUpAd from '../components/PopUpAd'; // Import the PopUpAd component
// import NewsPopup from '../components/NewsPopup'; // Import the NewsPopup component

// // Import RJ posters manually
// import RJ1 from '../assets/RJ-posters/1.png';
// import RJ2 from '../assets/RJ-posters/2.png';
// import RJ3 from '../assets/RJ-posters/3.png';
// import RJ4 from '../assets/RJ-posters/4.png';
// import RJ5 from '../assets/RJ-posters/5.png';
// import RJ6 from '../assets/RJ-posters/6.png';
// import RJ7 from '../assets/RJ-posters/7.png';
// import RJ8 from '../assets/RJ-posters/8.png';
// import RJ9 from '../assets/RJ-posters/9.png';
// import RJ10 from '../assets/RJ-posters/10.png';

// const Home = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
//   const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);
//   const { ads, isLoading: isAdsLoading, errorMessage: adsError } = useSelector((state) => state.ads); // Get ads from the Redux state

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [interestedCategories, setInterestedCategories] = useState([]);
//   const [showPopUpAd, setShowPopUpAd] = useState(false);
//   const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);
//   const [selectedNews, setSelectedNews] = useState(null); // Add state for selected news

//   const rjPosters = [RJ1, RJ2, RJ3, RJ4, RJ5, RJ6, RJ7, RJ8, RJ9, RJ10];

//   useEffect(() => {
//     dispatch(fetchProgramsList(true)); // Fetch programs for guest
//     dispatch(fetchNewsList(true)); // Fetch news for guest
//     dispatch(fetchCategoryList(true)); // Fetch categories for guest
//     dispatch(fetchAds(true)); // Fetch ads for guest
//   }, [dispatch]);

//   const bannerAds = ads.filter(ad => ad.type === '2');
//   const currentBannerAd = bannerAds[bannerAdIndex % bannerAds.length];

//   const toggleInterest = (categoryId) => {
//     setInterestedCategories(prevState =>
//       prevState.includes(categoryId)
//         ? prevState.filter(id => id !== categoryId)
//         : [...prevState, categoryId]
//     );
//   };

//   const handleNewsClick = (newsItem) => {
//     setSelectedNews(newsItem);
//   };

//   const closeNewsPopup = () => {
//     setSelectedNews(null);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setBannerAdIndex(prevIndex => prevIndex + 1);
//     }, 300000); // Rotate banner ad every 5 minutes

//     return () => clearInterval(interval);
//   }, []);

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//   };

//   return (
//     <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
//       {selectedNews && (
//         <NewsPopup news={selectedNews} onClose={closeNewsPopup} />
//       )}
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
//         <div className="relative h-96">
//           <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
//           <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
//         </div>
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Top <span className="text-blue-600">Shows</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* News and Categories Section */}
//         <div className="px-4 md:px-16 pt-0 md:pt-8 bg-gray-50 dark:bg-gray-900 flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16">
//           {/* Latest News Section */}
//           <div className="w-full md:w-1/2 pr-2">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//               Latest <span className="text-blue-600">News</span>
//             </h2>
//             <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
//             {isNewsLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
//             ) : newsError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
//             ) : (
//               <div className="relative">
//                 <div className="no-scrollbar overflow-x-scroll">
//                   <div className="flex space-x-6">
//                     {news.map((item) => (
//                       <div key={item.SK} className="min-w-[220px] max-w-[200px]" onClick={() => handleNewsClick(item)}>
//                         <img src={item.thumbnail} alt={item.title} className="w-full h-40 object-cover rounded-lg" />
//                         <div className="pt-2">
//                           <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
//                           <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
          
//           {/* Categories Section */}
//           <div className="w-full md:w-1/2 pl-2 hidden md:block">
//             <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//               Categories
//             </p>
//             {isCategoriesLoading ? (
//               <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//             ) : categoriesError ? (
//               <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//             ) : (
//               <div className="relative h-72 overflow-y-scroll no-scrollbar">
//                 <div className="flex flex-col space-y-6">
//                   {categories.map((category) => (
//                     <div key={category.PK} className="flex items-center space-x-4">
//                       <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
//                       <div className="ml-4 flex-1">
//                         <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
//                       </div>
//                       <button onClick={() => toggleInterest(category.PK)} className="ml-4 px-20">
//                         {interestedCategories.includes(category.PK) ? (
//                           <FaHeart className="text-red-500" />
//                         ) : (
//                           <FaRegHeart className="text-gray-400" />
//                         )}
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Banner Ads Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           {isAdsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Categories Section for Mobile */}
//         <div className="px-4 md:px-16 pt-4 md:hidden bg-gray-50 dark:bg-gray-900">
//           <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
//             Categories
//           </p>
//           {isCategoriesLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
//           ) : categoriesError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
//           ) : (
//             <div className="relative no-scrollbar overflow-x-scroll">
//               <div className="flex space-x-6">
//                 {categories.map((category) => (
//                   <div key={category.PK} className="flex-shrink-0">
//                     <img src={category.thumbnail} alt={category.title} className="w-40 h-40 object-cover rounded-lg" />
//                     <p className="text-center text-gray-900 dark:text-gray-100 mt-2">{category.title}</p>
//                     <button onClick={() => toggleInterest(category.PK)} className="block mx-auto mt-2">
//                       {interestedCategories.includes(category.PK) ? (
//                         <FaHeart className="text-red-500" />
//                       ) : (
//                         <FaRegHeart className="text-gray-400" />
//                       )}
//                     </button>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Our RJs Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">RJs</span>
//           </h2>
//           <div className="relative carousel-wrapper">
//             <Slider {...carouselSettings}>
//               {rjPosters.map((poster, index) => (
//                 <div key={index} className="p-4 md:p-14">
//                   <div className="carousel-item">
//                     <img src={poster} alt={`RJ ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
        
//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// export default Home;


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgramsList } from '../redux/actions/programsActions';
import { fetchNewsList } from '../redux/actions/newsActions';
import { fetchCategoryList } from '../redux/actions/categoriesActions';
import { fetchAds } from '../redux/actions/adsActions'; // Import the fetchAds action
import NavigationBar from '../components/NavigationBar';
import banner from '../assets/cover 007.png';
import "../styles/global.css";
import "../styles/carousel.css"; // Add a new CSS file for carousel styles
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import Slider from "react-slick";
import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component
import PopUpAd from '../components/PopUpAd'; // Import the PopUpAd component
import NewsPopup from '../components/NewsPopup'; // Import the NewsPopup component
import { Link } from 'react-router-dom';

// Import RJ posters manually
import RJ1 from '../assets/RJ-posters/1.png';
import RJ2 from '../assets/RJ-posters/2.png';
import RJ3 from '../assets/RJ-posters/3.png';
import RJ4 from '../assets/RJ-posters/4.png';
import RJ5 from '../assets/RJ-posters/5.png';
import RJ6 from '../assets/RJ-posters/6.png';
import RJ7 from '../assets/RJ-posters/7.png';
import RJ8 from '../assets/RJ-posters/8.png';
import RJ9 from '../assets/RJ-posters/9.png';
import RJ10 from '../assets/RJ-posters/10.png';

const Home = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
  const { news, isLoading: isNewsLoading, errorMessage: newsError } = useSelector((state) => state.news);
  const { categories, isLoading: isCategoriesLoading, errorMessage: categoriesError } = useSelector((state) => state.categories);
  const { ads, isLoading: isAdsLoading, errorMessage: adsError } = useSelector((state) => state.ads); // Get ads from the Redux state

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [interestedCategories, setInterestedCategories] = useState([]);
  const [showPopUpAd, setShowPopUpAd] = useState(false);
  const [currentPopUpAd, setCurrentPopUpAd] = useState(null);
  const [bannerAdIndex, setBannerAdIndex] = useState(0);
  const [selectedNews, setSelectedNews] = useState(null); // Add state for selected news
  const [showRegisterPopup, setShowRegisterPopup] = useState(false); // Add state for the registration popup

  const rjPosters = [RJ1, RJ2, RJ3, RJ4, RJ5, RJ6, RJ7, RJ8, RJ9, RJ10];

  useEffect(() => {
    dispatch(fetchProgramsList(true)); // Fetch programs for guest
    dispatch(fetchNewsList(true)); // Fetch news for guest
    dispatch(fetchCategoryList(true)); // Fetch categories for guest
    dispatch(fetchAds(true)); // Fetch ads for guest
  }, [dispatch]);

  const bannerAds = ads.filter(ad => ad.type === '2');
  const currentBannerAd = bannerAds[bannerAdIndex % bannerAds.length];

  const toggleInterest = (categoryId) => {
    setInterestedCategories(prevState =>
      prevState.includes(categoryId)
        ? prevState.filter(id => id !== categoryId)
        : [...prevState, categoryId]
    );
  };

  const handleNewsClick = (newsItem) => {
    setSelectedNews(newsItem);
  };

  const closeNewsPopup = () => {
    setSelectedNews(null);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerAdIndex(prevIndex => prevIndex + 1);
    }, 300000); // Rotate banner ad every 5 minutes

    return () => clearInterval(interval);
  }, []);

  const handleRestrictedNavigation = (e) => {
    if (userData.isGuest) {
      e.preventDefault();
      setShowRegisterPopup(true);
    }
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  };

  if (!userData) {
    return <p>Loading...</p>;
  }

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    centerMode: true,
    centerPadding: "0",
  };

  return (
    <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
      {selectedNews && (
        <NewsPopup news={selectedNews} onClose={closeNewsPopup} />
      )}
      {showRegisterPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
            <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
            <div className="flex space-x-4 justify-center">
              <Link to="/login" className="bg-blue-500 text-white px-4 py-2 rounded-md">Login</Link>
              <Link to="/signup" className="bg-green-500 text-white px-4 py-2 rounded-md">Signup</Link>
            </div>
            <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>Close</button>
          </div>
        </div>
      )}
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
        <div className="relative h-96">
          <img src={banner} alt="Banner" className="w-full h-96 object-cover" />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-transparent to-gray-50 dark:to-gray-900"></div>
        </div>
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Top <span className="text-blue-600">Shows</span>
          </h2>
          <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">You shouldn't miss today</p>
          {isProgramsLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
          ) : programsError ? (
            <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
          ) : (
            <div className="relative">
              <div className="no-scrollbar overflow-x-scroll">
                <div className="flex space-x-6">
                  {programs.map((program) => (
                    <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
                      <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
                      <div className="p-2">
                        <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* News and Categories Section */}
        <div className="px-4 md:px-16 pt-0 md:pt-8 bg-gray-50 dark:bg-gray-900 flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16">
          {/* Latest News Section */}
          <div className="w-full md:w-1/2 pr-2">
            <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
              Latest <span className="text-blue-600">News</span>
            </h2>
            <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Stay updated with the latest news</p>
            {isNewsLoading ? (
              <p className="text-gray-900 dark:text-gray-100">Loading news...</p>
            ) : newsError ? (
              <p className="text-red-600 dark:text-red-400">Error loading news: {newsError}</p>
            ) : (
              <div className="relative">
                <div className="no-scrollbar overflow-x-scroll">
                  <div className="flex space-x-6">
                    {news.map((item) => (
                      <div key={item.SK} className="min-w-[220px] max-w-[200px]" onClick={() => handleNewsClick(item)}>
                        <img src={item.thumbnail} alt={item.title} className="w-full h-40 object-cover rounded-lg" />
                        <div className="pt-2">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{item.title}</h3>
                          <p className="text-gray-600 dark:text-gray-400">{new Date(item.updatedAt).toLocaleDateString()}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Categories Section */}
          <div className="w-full md:w-1/2 pl-2 hidden md:block">
            <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
              Categories
            </p>
            {isCategoriesLoading ? (
              <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
            ) : categoriesError ? (
              <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
            ) : (
              <div className="relative h-72 overflow-y-scroll no-scrollbar">
                <div className="flex flex-col space-y-6">
                  {categories.map((category) => (
                    <div key={category.PK} className="flex items-center space-x-4">
                      <img src={category.thumbnail} alt={category.title} className="w-16 h-16 object-cover rounded-lg" />
                      <div className="ml-4 flex-1">
                        <h3 className="text-l font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{category.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">Shows: {category.programCount}</p>
                      </div>
                      <button onClick={() => toggleInterest(category.PK)} className="ml-4 px-20">
                        {interestedCategories.includes(category.PK) ? (
                          <FaHeart className="text-red-500" />
                        ) : (
                          <FaRegHeart className="text-gray-400" />
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Banner Ads Section */}
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          {isAdsLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
          ) : adsError ? (
            <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
          ) : (
            <div className="relative">
              {currentBannerAd && (
                <div className="flex justify-center items-center">
                  <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
                    <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
                  </a>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Categories Section for Mobile */}
        <div className="px-4 md:px-16 pt-4 md:hidden bg-gray-50 dark:bg-gray-900">
          <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 tracking-[.15em]">
            Categories
          </p>
          {isCategoriesLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading categories...</p>
          ) : categoriesError ? (
            <p className="text-red-600 dark:text-red-400">Error loading categories: {categoriesError}</p>
          ) : (
            <div className="relative no-scrollbar overflow-x-scroll">
              <div className="flex space-x-6">
                {categories.map((category) => (
                  <div key={category.PK} className="flex-shrink-0">
                    <img src={category.thumbnail} alt={category.title} className="w-40 h-40 object-cover rounded-lg" />
                    <p className="text-center text-gray-900 dark:text-gray-100 mt-2">{category.title}</p>
                    <button onClick={() => toggleInterest(category.PK)} className="block mx-auto mt-2">
                      {interestedCategories.includes(category.PK) ? (
                        <FaHeart className="text-red-500" />
                      ) : (
                        <FaRegHeart className="text-gray-400" />
                      )}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Our RJs Section */}
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Our <span className="text-blue-600">RJs</span>
          </h2>
          <div className="relative carousel-wrapper">
            <Slider {...carouselSettings}>
              {rjPosters.map((poster, index) => (
                <div key={index} className="p-4 md:p-14">
                  <div className="carousel-item">
                    <img src={poster} alt={`RJ ${index + 1}`} className="w-full h-full object-cover rounded-lg" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        
        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default Home;

// import React, { useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { FaSun, FaMoon } from 'react-icons/fa';
// import { HiOutlineHome, HiOutlineMusicNote, HiOutlineCalendar, HiOutlineInformationCircle, HiOutlineShieldCheck, HiOutlineLogout } from 'react-icons/hi';
// import { useSelector } from 'react-redux';
// import logoBlack from '../assets/logo_black.png';
// import logoWhite from '../assets/logo.png';
// import collapsedLogo from '../assets/RS.PNG';

// const NavigationBar = ({ setIsCollapsed }) => {
//   const [darkMode, setDarkMode] = useState(false);
//   const location = useLocation();
//   const userData = useSelector((state) => state.user.userData);

//   const toggleDarkMode = () => {
//     setDarkMode(!darkMode);
//     document.documentElement.classList.toggle('dark');
//   };

//   const handleLogout = () => {
//     // Add your logout logic here
//     console.log('Logout clicked');
//   };

//   const getLinkClass = (path) => {
//     return location.pathname === path
//       ? 'flex items-center p-2 bg-gradient-to-r from-blue-400 dark:to-gray-900 to-white text-white rounded-md transition-all duration-300'
//       : 'flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md transition-all duration-300';
//   };

//   return (
//     <div
//       className="relative group flex flex-col h-screen p-4 w-20 bg-white dark:bg-gray-900 text-black dark:text-white hover:w-64 transition-all duration-300 ease-in-out"
//       onMouseEnter={() => setIsCollapsed(false)}
//       onMouseLeave={() => setIsCollapsed(true)}
//     >
//       <div className="flex items-center justify-center pb-4">
//         <img src={collapsedLogo} alt="Collapsed Logo" className="h-10 mt-4 block group-hover:hidden mx-auto" />
//         <img src={darkMode ? logoWhite : logoBlack} alt="Logo" className="h-10 mt-4 hidden group-hover:block" />
//       </div>
//       <nav className="flex-1 mt-10 space-y-2">
//         <Link to="/" className={`${getLinkClass('/')} justify-center group-hover:justify-start`}>
//           <HiOutlineHome className="text-lg" />
//           <span className="ml-4 hidden group-hover:block truncate">Home</span>
//         </Link>
//         <Link to="/sverse" className={`${getLinkClass('/sverse')} justify-center group-hover:justify-start`}>
//           <HiOutlineMusicNote className="text-lg" />
//           <span className="ml-4 hidden group-hover:block truncate">S-Verse</span>
//         </Link>
//         <Link to="/schedule" className={`${getLinkClass('/schedule')} justify-center group-hover:justify-start`}>
//           <HiOutlineCalendar className="text-lg" />
//           <span className="ml-4 hidden group-hover:block truncate">Schedule</span>
//         </Link>
//         <Link to="/about" className={`${getLinkClass('/about')} justify-center group-hover:justify-start`}>
//           <HiOutlineInformationCircle className="text-lg" />
//           <span className="ml-4 hidden group-hover:block truncate">About Us</span>
//         </Link>
//       </nav>
//       <div className="mt-auto pt-4">
//         <div className="flex items-center mb-4 transition-all duration-300 justify-center group-hover:justify-start">
//           <img 
//             src={userData.profilePic} 
//             alt="User Avatar" 
//             className="h-10 w-10 rounded-full border-2 border-blue-500 mx-auto group-hover:mx-0" 
//           />
//           <span className="ml-4 hidden group-hover:block truncate whitespace-nowrap overflow-hidden">{userData.name}</span>
//         </div>
//         <button onClick={toggleDarkMode} className="flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md mb-2 transition-all duration-300 justify-center group-hover:justify-start">
//           {darkMode ? <FaSun className="text-lg mx-auto" /> : <FaMoon className="text-lg mx-auto" />}
//           <span className="ml-4 hidden group-hover:block truncate">{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
//         </button>
//         <Link to="/privacy" className={`${getLinkClass('/privacy')} justify-center group-hover:justify-start`}>
//           <HiOutlineShieldCheck className="text-lg" />
//           <span className="ml-4 hidden group-hover:block truncate">Privacy Policy</span>
//         </Link>
//         <button onClick={handleLogout} className="flex items-center justify-center w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md mt-4 transition-all duration-300">
//           <div className="flex items-center justify-center w-full">
//             <HiOutlineLogout className="text-lg" />
//             <span className="ml-4 hidden group-hover:block truncate">Logout</span>
//           </div>
//         </button>
//       </div>
//     </div>
//   );
// };

// import React, { useState } from 'react';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { FaSun, FaMoon, FaBars, FaTimes } from 'react-icons/fa';
// import { HiOutlineHome, HiOutlineMusicNote, HiOutlineCalendar, HiOutlineInformationCircle, HiOutlineShieldCheck, HiOutlineLogout } from 'react-icons/hi';
// import { useSelector } from 'react-redux';
// import logoBlack from '../assets/logo_black.png';
// import logoWhite from '../assets/logo.png';
// import collapsedLogo from '../assets/RS.PNG';
// import { signOut } from '@aws-amplify/auth';

// const NavigationBar = ({ setIsCollapsed }) => {
//   const [darkMode, setDarkMode] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const userData = useSelector((state) => state.user.userData);

//   const toggleDarkMode = () => {
//     setDarkMode(!darkMode);
//     document.documentElement.classList.toggle('dark');
//   };

//   const handleLogout = async () => {
//     await signOut();
//     navigate('/');
//     console.log('Logout clicked');
//   };

//   const getLinkClass = (path) => {
//     return location.pathname === path
//       ? 'flex items-center p-2 bg-gradient-to-r from-blue-400 dark:to-gray-900 to-white text-white rounded-md transition-all duration-300'
//       : 'flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md transition-all duration-300';
//   };

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   return (
//     <div>
//       <div
//         className="relative group flex flex-col h-screen p-4 w-20 bg-white dark:bg-gray-900 text-black dark:text-white hover:w-64 transition-all duration-300 ease-in-out lg:flex hidden"
//         onMouseEnter={() => setIsCollapsed(false)}
//         onMouseLeave={() => setIsCollapsed(true)}
//       >
//         <div className="flex items-center justify-center pb-4">
//           <img src={collapsedLogo} alt="Collapsed Logo" className="h-10 mt-4 block group-hover:hidden mx-auto" />
//           <img src={darkMode ? logoWhite : logoBlack} alt="Logo" className="h-10 mt-4 hidden group-hover:block" />
//         </div>
//         <nav className="flex-1 mt-10 space-y-2">
//           <Link to="/" className={`${getLinkClass('/')} justify-center group-hover:justify-start`}>
//             <HiOutlineHome className="text-lg" />
//             <span className="ml-4 hidden group-hover:block truncate">Home</span>
//           </Link>
//           <Link to="/sverse" className={`${getLinkClass('/sverse')} justify-center group-hover:justify-start`}>
//             <HiOutlineMusicNote className="text-lg" />
//             <span className="ml-4 hidden group-hover:block truncate">S-Verse</span>
//           </Link>
//           <Link to="/schedule" className={`${getLinkClass('/schedule')} justify-center group-hover:justify-start`}>
//             <HiOutlineCalendar className="text-lg" />
//             <span className="ml-4 hidden group-hover:block truncate">Schedule</span>
//           </Link>
//           <Link to="/about" className={`${getLinkClass('/about')} justify-center group-hover:justify-start`}>
//             <HiOutlineInformationCircle className="text-lg" />
//             <span className="ml-4 hidden group-hover:block truncate">About Us</span>
//           </Link>
//         </nav>
//         <div className="mt-auto pt-4">
//           <div className="flex items-center mb-4 transition-all duration-300 justify-center group-hover:justify-start">
//             <img 
//               src={userData.profilePic} 
//               alt="User Avatar" 
//               className="h-10 w-10 rounded-full border-2 border-blue-500 mx-auto group-hover:mx-0" 
//             />
//             <span className="ml-4 hidden group-hover:block truncate whitespace-nowrap overflow-hidden">{userData.name}</span>
//           </div>
//           <button onClick={toggleDarkMode} className="flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md mb-2 transition-all duration-300 justify-center group-hover:justify-start">
//             {darkMode ? <FaSun className="text-lg mx-auto" /> : <FaMoon className="text-lg mx-auto" />}
//             <span className="ml-4 hidden group-hover:block truncate">{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
//           </button>
//           <Link to="/privacy" className={`${getLinkClass('/privacy')} justify-center group-hover:justify-start`}>
//             <HiOutlineShieldCheck className="text-lg" />
//             <span className="ml-4 hidden group-hover:block truncate">Privacy Policy</span>
//           </Link>
//           <button onClick={handleLogout} className="flex items-center justify-center w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md mt-4 transition-all duration-300">
//             <div className="flex items-center justify-center w-full">
//               <HiOutlineLogout className="text-lg" />
//               <span className="ml-4 hidden group-hover:block truncate">Logout</span>
//             </div>
//           </button>
//         </div>
//       </div>

//       {/* Mobile Menu */}
//       <div className="lg:hidden fixed top-0 left-0 w-screen p-4 bg-white dark:bg-gray-900 z-50 flex justify-between items-center">
//         <img src={darkMode ? logoWhite : logoBlack} alt="Logo" className="h-10" />
//         <button onClick={toggleMobileMenu}>
//           {isMobileMenuOpen ? <FaTimes className="text-xl" /> : <FaBars className="text-xl" />}
//         </button>
//       </div>

//       {isMobileMenuOpen && (
//         <div className="lg:hidden fixed inset-0 bg-white dark:bg-gray-900 z-40 p-4 flex flex-col pt-20">
//           <nav className="flex-1 mt-10 space-y-2">
//             <Link to="/" className={getLinkClass('/')}>
//               <HiOutlineHome className="text-lg" />
//               <span className="ml-4">Home</span>
//             </Link>
//             <Link to="/sverse" className={getLinkClass('/sverse')}>
//               <HiOutlineMusicNote className="text-lg" />
//               <span className="ml-4">S-Verse</span>
//             </Link>
//             <Link to="/schedule" className={getLinkClass('/schedule')}>
//               <HiOutlineCalendar className="text-lg" />
//               <span className="ml-4">Schedule</span>
//             </Link>
//             <Link to="/about" className={getLinkClass('/about')}>
//               <HiOutlineInformationCircle className="text-lg" />
//               <span className="ml-4">About Us</span>
//             </Link>
//           </nav>
//           <div className="mt-auto pt-4">
//             <div className="flex items-center mb-4">
//               <img 
//                 src={userData.profilePic} 
//                 alt="User Avatar" 
//                 className="h-10 w-10 rounded-full border-2 border-blue-500" 
//               />
//               <span className="ml-4 truncate">{userData.name}</span>
//             </div>
//             <button onClick={toggleDarkMode} className="flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md mb-2">
//               {darkMode ? <FaSun className="text-lg" /> : <FaMoon className="text-lg" />}
//               <span className="ml-4">{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
//             </button>
//             <Link to="/privacy" className={getLinkClass('/privacy')}>
//               <HiOutlineShieldCheck className="text-lg" />
//               <span className="ml-4">Privacy Policy</span>
//             </Link>
//             <button onClick={handleLogout} className="flex items-center w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md mt-4">
//               <div className="flex items-center w-full">
//                 <HiOutlineLogout className="text-lg" />
//                 <span className="ml-4">Logout</span>
//               </div>
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default NavigationBar;
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaSun, FaMoon, FaBars, FaTimes } from 'react-icons/fa';
import { HiOutlineHome, HiOutlineMusicNote, HiOutlineCalendar, HiOutlineInformationCircle, HiOutlineShieldCheck, HiOutlineLogout } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import logoBlack from '../assets/logo_black.png';
import logoWhite from '../assets/logo.png';
import collapsedLogo from '../assets/RS.PNG';
import { signOut } from '@aws-amplify/auth';

const NavigationBar = ({ setIsCollapsed }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };

  const handleLogout = async () => {
    await signOut();
    navigate('/');
    console.log('Logout clicked');
  };

  const getLinkClass = (path) => {
    return location.pathname === path
      ? 'flex items-center p-2 bg-gradient-to-r from-blue-400 dark:to-gray-900 to-white text-white rounded-md transition-all duration-300'
      : 'flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md transition-all duration-300';
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleRestrictedNavigation = (e, path) => {
    if (userData?.isGuest) {
      e.preventDefault();
      setShowRegisterPopup(true);
    } else {
      navigate(path);
    }
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  };

  return (
    <div>
      {showRegisterPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg text-center">
            <p className="text-gray-900 dark:text-gray-100 mb-4">Register with us for complete access</p>
            <div className="flex space-x-4 justify-center">
              <Link to="/login" className="bg-blue-500 text-white px-4 py-2 rounded-md">Login</Link>
              <Link to="/signup" className="bg-green-500 text-white px-4 py-2 rounded-md">Signup</Link>
            </div>
            <button className="mt-4 text-gray-500" onClick={closeRegisterPopup}>Close</button>
          </div>
        </div>
      )}
      <div
        className="relative group flex flex-col h-screen p-4 w-20 bg-white dark:bg-gray-900 text-black dark:text-white hover:w-64 transition-all duration-300 ease-in-out lg:flex hidden"
        onMouseEnter={() => setIsCollapsed(false)}
        onMouseLeave={() => setIsCollapsed(true)}
      >
        <div className="flex items-center justify-center pb-4">
          <img src={collapsedLogo} alt="Collapsed Logo" className="h-10 mt-4 block group-hover:hidden mx-auto" />
          <img src={darkMode ? logoWhite : logoBlack} alt="Logo" className="h-10 mt-4 hidden group-hover:block" />
        </div>
        <nav className="flex-1 mt-10 space-y-2">
          <a href="/" className={`${getLinkClass('/')} justify-center group-hover:justify-start`} onClick={(e) => handleRestrictedNavigation(e, '/')}>
            <HiOutlineHome className="text-lg" />
            <span className="ml-4 hidden group-hover:block truncate">Home</span>
          </a>
          <a href="/sverse" className={`${getLinkClass('/sverse')} justify-center group-hover:justify-start`} onClick={(e) => handleRestrictedNavigation(e, '/sverse')}>
            <HiOutlineMusicNote className="text-lg" />
            <span className="ml-4 hidden group-hover:block truncate">S-Verse</span>
          </a>
          <a href="/schedule" className={`${getLinkClass('/schedule')} justify-center group-hover:justify-start`} onClick={(e) => handleRestrictedNavigation(e, '/schedule')}>
            <HiOutlineCalendar className="text-lg" />
            <span className="ml-4 hidden group-hover:block truncate">Schedule</span>
          </a>
          <a href="/about" className={`${getLinkClass('/about')} justify-center group-hover:justify-start`} onClick={(e) => handleRestrictedNavigation(e, '/about')}>
            <HiOutlineInformationCircle className="text-lg" />
            <span className="ml-4 hidden group-hover:block truncate">About Us</span>
          </a>
        </nav>
        <div className="mt-auto pt-4">
          <div className="flex items-center mb-4 transition-all duration-300 justify-center group-hover:justify-start">
            {userData?.profilePic && (
              <img 
                src={userData.profilePic} 
                alt="User Avatar" 
                className="h-10 w-10 rounded-full border-2 border-blue-500 mx-auto group-hover:mx-0" 
              />
            )}
            <span className="ml-4 hidden group-hover:block truncate whitespace-nowrap overflow-hidden">{userData?.name || 'Guest'}</span>
          </div>
          <button onClick={toggleDarkMode} className="flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md mb-2 transition-all duration-300 justify-center group-hover:justify-start">
            {darkMode ? <FaSun className="text-lg mx-auto" /> : <FaMoon className="text-lg mx-auto" />}
            <span className="ml-4 hidden group-hover:block truncate">{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
          </button>
          <a href="/privacy" className={`${getLinkClass('/privacy')} justify-center group-hover:justify-start`} onClick={(e) => handleRestrictedNavigation(e, '/privacy')}>
            <HiOutlineShieldCheck className="text-lg" />
            <span className="ml-4 hidden group-hover:block truncate">Privacy Policy</span>
          </a>
          {userData?.isGuest ? (
            <button onClick={() => navigate('/signup')} className="flex items-center justify-center w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md mt-4 transition-all duration-300">
              <div className="flex items-center justify-center w-full">
                <HiOutlineLogout className="text-lg" />
                <span className="ml-4 hidden group-hover:block truncate">Register</span>
              </div>
            </button>
          ) : (
            <button onClick={handleLogout} className="flex items-center justify-center w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md mt-4 transition-all duration-300">
              <div className="flex items-center justify-center w-full">
                <HiOutlineLogout className="text-lg" />
                <span className="ml-4 hidden group-hover:block truncate">Logout</span>
              </div>
            </button>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="lg:hidden fixed top-0 left-0 w-screen p-4 bg-white dark:bg-gray-900 z-50 flex justify-between items-center">
        <img src={darkMode ? logoWhite : logoBlack} alt="Logo" className="h-10" />
        <button onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes className="text-xl" /> : <FaBars className="text-xl" />}
        </button>
      </div>

      {isMobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 bg-white dark:bg-gray-900 z-40 p-4 flex flex-col pt-20">
          <nav className="flex-1 mt-10 space-y-2">
            <a href="/" className={getLinkClass('/')} onClick={(e) => handleRestrictedNavigation(e, '/')}>
              <HiOutlineHome className="text-lg" />
              <span className="ml-4">Home</span>
            </a>
            <a href="/sverse" className={getLinkClass('/sverse')} onClick={(e) => handleRestrictedNavigation(e, '/sverse')}>
              <HiOutlineMusicNote className="text-lg" />
              <span className="ml-4">S-Verse</span>
            </a>
            <a href="/schedule" className={getLinkClass('/schedule')} onClick={(e) => handleRestrictedNavigation(e, '/schedule')}>
              <HiOutlineCalendar className="text-lg" />
              <span className="ml-4">Schedule</span>
            </a>
            <a href="/about" className={getLinkClass('/about')} onClick={(e) => handleRestrictedNavigation(e, '/about')}>
              <HiOutlineInformationCircle className="text-lg" />
              <span className="ml-4">About Us</span>
            </a>
          </nav>
          <div className="mt-auto pt-4">
            {userData?.profilePic && (
              <div className="flex items-center mb-4">
                <img 
                  src={userData.profilePic} 
                  alt="User Avatar" 
                  className="h-10 w-10 rounded-full border-2 border-blue-500" 
                />
                <span className="ml-4 truncate">{userData.name}</span>
              </div>
            )}
            <button onClick={toggleDarkMode} className="flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md mb-2">
              {darkMode ? <FaSun className="text-lg" /> : <FaMoon className="text-lg" />}
              <span className="ml-4">{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </button>
            <a href="/privacy" className={getLinkClass('/privacy')} onClick={(e) => handleRestrictedNavigation(e, '/privacy')}>
              <HiOutlineShieldCheck className="text-lg" />
              <span className="ml-4">Privacy Policy</span>
            </a>
            {userData?.isGuest ? (
              <button onClick={() => navigate('/signup')} className="flex items-center w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md mt-4">
                <div className="flex items-center w-full">
                  <HiOutlineLogout className="text-lg" />
                  <span className="ml-4">Register</span>
                </div>
              </button>
            ) : (
              <button onClick={handleLogout} className="flex items-center w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md mt-4">
                <div className="flex items-center w-full">
                  <HiOutlineLogout className="text-lg" />
                  <span className="ml-4">Logout</span>
                </div>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationBar;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlay, FaPause } from 'react-icons/fa';
import RotatingImageWidget from '../components/RotatingImageWidget';
import NavigationBar from '../components/NavigationBar';
import { useParams } from 'react-router-dom';
import { useAbly } from '../utils/useAbly';
import { togglePlayPause } from '../redux/reducers/streamingBarSlice';

const DetailedStreamingBar = () => {
  const dispatch = useDispatch();
  const { programId } = useParams();
  const { currentProgram } = useSelector((state) => state.programs); // Ensure correct slice
  const { currentRunningProgram, isPlaying, liveStreamUrl, archiveUrl } = useSelector((state) => state.streamingBar);
  const { comments, newComment } = useAbly(`channelRules:${programId}`);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [message, setMessage] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);

  const audioRef = useRef(new Audio());

  useEffect(() => {
    const audio = audioRef.current;

    if (archiveUrl) {
      audio.src = archiveUrl;
    } else {
      audio.src = liveStreamUrl;
    }

    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }

    return () => {
      audio.pause();
    };
  }, [isPlaying, liveStreamUrl, archiveUrl]);

  const handleTogglePlayback = () => {
    dispatch(togglePlayPause());
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      newComment(message);
      setMessage('');
    }
  };

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark');
  };

  if (!currentRunningProgram) {
    return <div>Loading...</div>; // Handle loading state
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`flex-1 transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-20' : 'ml-64'}`}>
        <div className="p-8 bg-gray-50 dark:bg-gray-900 min-h-screen flex flex-col md:flex-row">
          <div className="md:w-1/2 flex flex-col items-center justify-center space-y-8">
            <RotatingImageWidget imageUrl={currentRunningProgram.showPic} />
            <button
              className="text-gray-800 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 text-4xl"
              onClick={handleTogglePlayback}
            >
              {isPlaying ? <FaPause /> : <FaPlay />}
            </button>
            <div className="mt-4 text-center">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                {currentRunningProgram.title}
              </h2>
              <p className="text-xl text-gray-600 dark:text-gray-400">
                {currentRunningProgram.rjName}
              </p>
            </div>
          </div>
          <div className="md:w-1/2 p-4 pt-12">
            <div className="flex flex-col space-y-4 h-auto">
              <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex-1">
                <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100 text-center">Comments</h3>
                <div className="overflow-y-scroll h-96">
                  {comments.map((comment, index) => (
                    <div key={index} className="p-2 border-b border-gray-200 dark:border-gray-700">
                      <p className="text-sm text-gray-600 dark:text-gray-400">{comment.name || 'Anonymous'}</p>
                      <p className="text-gray-900 dark:text-gray-100">{comment.data}</p>
                    </div>
                  ))}
                </div>
                <div className="mt-4">
                  <textarea
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write a comment..."
                  />
                  <button
                    className="mt-2 w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
                    onClick={handleSendMessage}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedStreamingBar;

// // src/redux/actions/programsActions.js
// import { fetchProgramsStart, fetchProgramsSuccess, fetchProgramsFailure } from '../reducers/programsSlice';
// import apiService from '../api-service/apiService';

// export const fetchProgramsList = (forGuest) => async (dispatch, getState) => {
//   dispatch(fetchProgramsStart());

//   try {
//     const { userData } = getState().user;
//     const programs = await apiService.fetchProgramsList(forGuest, userData);
//     dispatch(fetchProgramsSuccess(programs));
//   } catch (error) {
//     dispatch(fetchProgramsFailure(error.message));
//   }
// };

// src/redux/actions/programsActions.js
import { fetchProgramsStart, fetchProgramsSuccess, fetchProgramsFailure } from '../reducers/programsSlice';
import { setProgram } from '../reducers/streamingBarSlice'; // Import setProgram action from streamingBarSlice
import apiService from '../api-service/apiService';
import logo from '../../assets/logo.png';

// Helper function to convert time strings like "4:30 PM" to minutes from midnight
const convertTimeToMinutes = (timeString) => {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  return hours * 60 + minutes;
};

const getCurrentRunningProgram = (programs) => {
  const now = new Date();
  const nowTime = now.getHours() * 60 + now.getMinutes(); // Current time in minutes from midnight
  console.log(nowTime);

  for (const program of programs) {
    const startTime = convertTimeToMinutes(program.fromTime);
    const endTime = convertTimeToMinutes(program.toTime);
    console.log(nowTime, startTime, endTime, program.fromTime);

    // Handle programs that span midnight
    if (endTime < startTime) {
      if (nowTime >= startTime || nowTime <= endTime) {
        return {
          id: program.PK.split("#")[1],
          title: program.title,
          rjName: program.rjName,
          showPic: program.showPic,
        };
      }
    } else if (nowTime >= startTime && nowTime <= endTime) {
      return {
        id: program.PK.split("#")[1],
        title: program.title,
        rjName: program.rjName,
        showPic: program.showPic,
      };
    }
  }

  return {
    id: null,
    title: 'Radio Surabahi Playlist',
    rjName: null,
    showPic: logo,
  };
};

export const fetchProgramsList = (forGuest) => async (dispatch, getState) => {
  dispatch(fetchProgramsStart());

  try {
    const { userData } = getState().user;
    const programs = await apiService.fetchProgramsList(forGuest, userData);
    // Sort programs by start time to handle them in the correct order
    const sortPro = programs;
    const sortPrograms = sortPro.sort((a, b) => convertTimeToMinutes(a.fromTime) - convertTimeToMinutes(b.fromTime));

    const currentRunningProgram = getCurrentRunningProgram(sortPrograms);
    dispatch(fetchProgramsSuccess({ programs, currentRunningProgram }));
    dispatch(setProgram(currentRunningProgram)); // Set the current running program in the streaming bar

    // Update current running program every minute
    setInterval(() => {
      const updatedRunningProgram = getCurrentRunningProgram(programs);
      console.log(updatedRunningProgram);
      dispatch(updateCurrentRunningProgram(updatedRunningProgram));
      dispatch(setProgram(updatedRunningProgram)); // Update the current running program in the streaming bar
    }, 60000); // 60000ms = 1 minute
  } catch (error) {
    dispatch(fetchProgramsFailure(error.message));
  }
};

export const updateCurrentRunningProgram = (currentRunningProgram) => ({
  type: 'UPDATE_CURRENT_RUNNING_PROGRAM',
  payload: currentRunningProgram,
});

import axios from 'axios';
import { format, subDays } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = {
  fetchProgramsList: async (forGuest, userData) => {
    console.log(BASE_URL);
    const nowLocal = new Date();
    const ctLocation = 'America/Chicago';
    const nowCT = toZonedTime(nowLocal, ctLocation);
    const dayOfWeek = format(nowCT, 'EEE').toLowerCase();
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedTimeZone = currentTimeZone.replace('/', '-');

    let url;
    if (forGuest) {
      url = `${BASE_URL}isGuest/prg-list/${dayOfWeek}/timeZone/${formattedTimeZone}`;
    } else {
      const userId = userData.id; // Assuming userData contains id
      const accessToken = userData.identityToken; // Assuming userData contains identityToken
      url = `${BASE_URL}${userId}/prg-list/${dayOfWeek}/timeZone/${formattedTimeZone}`;
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      axios.defaults.headers.common['Accept'] = "application/json";
      axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded";
    }

    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch programs');
    }
  },

  fetchNewsList: async (forGuest, accessToken, userId) => {
    try {
      let url;
      if (forGuest) {
        url = `${BASE_URL}isGuest/news-list`;
      } else {
        url = `${BASE_URL}${userId}/news-list`;
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch news');
      }
    } catch (error) {
      console.error('Error fetching news list:', error);
      throw error;
    }
  }, 

  fetchCategoryList: async (forGuest, accessToken, userId) => {
    try {
      let url;
      if (forGuest) {
        url = `${BASE_URL}isGuest/category-list`;
      } else {
        url = `${BASE_URL}${userId}/category-list`;
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  },

  fetchArchivedPrograms: async (userData, selectedDate) => {
    const date = selectedDate ? new Date(selectedDate) : subDays(new Date(), 1); // Use yesterday's date if selectedDate is not provided
    const formattedDate = format(date, 'yyyy-MM-dd');

    const userId = userData.id;
    const accessToken = userData.identityToken;

    if (!userId || !accessToken) {
      throw new Error('User ID or Access Token is missing.');
    }

    const url = `${BASE_URL}${userId}/archive-list/date/${formattedDate}`;
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch archived programs');
    }
  },

  fetchMoviesList: async (userData) => {
    const accessToken = userData.identityToken;
    const userId = userData.id;

    if (!userId || !accessToken) {
      throw new Error('User ID or Access Token is missing.');
    }

    const url = `${BASE_URL}${userId}/movies-list`;
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      console.log(response.data)
      return response.data;
    } else {
      throw new Error('Failed to fetch movies');
    }
  },

  toggleLike: async (userData, movieId, action) => {
    const url = `${BASE_URL}movies-like`;
    const response = await axios.post(url, {
      id: userData.id,
      movieId: movieId.split('#')[1],
      action,
    }, {
      headers: {
        'Authorization': `Bearer ${userData.identityToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to update like status');
    }
  },

  fetchAds: async (forGuest, userData) => {
    let url;
    if (forGuest) {
      url = `${BASE_URL}isGuest/ads-list`;
    } else {
      const userId = userData.id;
      const accessToken = userData.identityToken;
      url = `${BASE_URL}${userId}/ads-list`;
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch ads');
    }
  },

  
};

export default apiService;

// src/components/PopUpAd.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';

const PopUpAd = ({ ad, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-4 relative max-w-lg w-96 dark:bg-gray-900">
        <button onClick={onClose} className="absolute top-2 left-2 p-2">
          <FaTimes />
        </button>
        <a href={ad.redirectionLink} target="_blank" rel="noopener noreferrer">
          <img src={ad.thumbnail} alt={ad.title} className="w-full h-[36rem] object-contain rounded-lg" />
        </a>
        
      </div>
    </div>
  );
};

export default PopUpAd;

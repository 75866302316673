// // src/App.js
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Login from './pages/Login';
// import Home from './pages/Home';
// import SVerse from './pages/Sverse';
// import Schedule from './pages/Schedule';
// import AboutUs from './pages/AboutUs';
// import AdManager from './components/AdManager';
// import NavigationBar from './components/NavigationBar';
// import StreamingBar from './components/StreamingBar';
// import Profile from './pages/Profile';
// import Privacy from './pages/Privacy';
// import DetailedStreamingBar from './pages/DetailedStreamingBar';
// import Signup from './pages/Signup';

// const App = () => {
//   const userData = useSelector((state) => state.user.userData);

//   return (
//     <Router>
//       <div className="App">
//         <AdManager />
//         <Routes>
//           <Route path="/" element={userData ? <Home /> : <Navigate to="/login" />} />
//           <Route path="/login" element={!userData ? <Login /> : <Navigate to="/" />} />
//           <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/" />} />
//           <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
//           <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
//           <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
//           <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
//           <Route path="/privacy" element={userData ? <Privacy /> : <Navigate to="/login" />} />
//           <Route path='/streaming/:programId' element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './pages/Login';
import Home from './pages/Home';
import SVerse from './pages/Sverse';
import Schedule from './pages/Schedule';
import AboutUs from './pages/AboutUs';
import AdManager from './components/AdManager';
import NavigationBar from './components/NavigationBar';
import StreamingBar from './components/StreamingBar';
import Profile from './pages/Profile';
import Privacy from './pages/Privacy';
import DetailedStreamingBar from './pages/DetailedStreamingBar';
import Signup from './pages/Signup';

const App = () => {
  const userData = useSelector((state) => state.user.userData);

  return (
    <Router>
      <div className="App">
        <AdManager />
        <Routes>
          <Route path="/" element={userData ? <Home /> : <Navigate to="/login" />} />
          <Route path="/login" element={!userData ? <Login /> : <Navigate to="/" />} />
          <Route path="/signup" element={!userData ? <Signup /> : <Navigate to="/" />} />
          <Route path="/sverse" element={userData ? <SVerse /> : <Navigate to="/login" />} />
          <Route path="/schedule" element={userData ? <Schedule /> : <Navigate to="/login" />} />
          <Route path="/about" element={userData ? <AboutUs /> : <Navigate to="/login" />} />
          <Route path="/profile" element={userData ? <Profile /> : <Navigate to="/login" />} />
          <Route path="/privacy" element={userData ? <Privacy /> : <Navigate to="/login" />} />
          <Route path='/streaming/:programId' element={userData ? <DetailedStreamingBar /> : <Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
